* {
  margin: 0;
  /* padding: 0; */
  box-sizing: border-box;
}

html, input, select, textarea, button {
  font-size: min(100%, calc(1vw * .5 + 12px));
  line-height: 1.5;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

html, body, #root {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
  /* border: solid 1px purple; */
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.5rem;
}

h1, h2, h3, h4, h5 {
  font-weight: 600;
  line-height: 1.5;
  margin-top: .6rem;
  margin-bottom: .3rem;
}

h1:first-child {
  margin-top: 0;
}