input[type="text"],
textarea {
  padding: 0 .3rem;
}

textarea {
  width: 15rem;
  height: 5rem;
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 1.875rem;
}

h3 {
  font-size: 1.3rem;
}

h4 {
  font-size: 1.1rem;
}

h5 {
  font-size: 1rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.2;
  margin: 1rem 0 .5rem 0;
}

h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
  margin-top: 0;
}

header h1,
header h2,
header h3,
header h4 {
  margin: 0;
}


.NoMargin {
  margin: 0;
}

header {
  background: #efefef;
}

span {}

main {
  flex: 1;
  align-items: stretch;
}

footer {
  height: 3rem;
  background: #efefef;
  display: flex;
  gap: .5rem;
  align-items: center;
  justify-content: space-between;
  padding: .6rem;
}

h1.Jumbo {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.1;
  padding: 1rem 0 1rem 0;

}

p.Jumbo {
  font-size: 1.2rem;
  line-height: 1.5;
  margin: 1rem 0 1rem 0;

  padding: 0 10rem 0 1.5rem;
  border-left: 5px solid orange;
}

.Flex {
  display: flex;
  gap: .5rem;
}

.FlexRow {
  flex-direction: row;
}

.FlexColumn {
  flex-direction: column;
}

.JustifyCenter {
  justify-content: center;
}

.JustifyStretch {
  justify-content: stretch;
}

.JustifyBetween {
  justify-content: space-between;
}

.JustifyStart {
  justify-content: flex-start;
}

.JustifyEnd {
  justify-content: flex-end;
}


.AlignCenter {
  align-items: center;
}

.AlignStretch {
  align-items: stretch;
}

.AlignStart {
  align-items: flex-start;
}

.AlignEnd {
  align-items: flex-end;
}

.AlignBase {
  align-items: baseline;
}

.DevBorderRed {
  border: 2px solid red;
}

.DevBorderPurple {
  border: 2px solid purple;
}

.Flex1 {
  flex: 1 1;
}

.Flex0 {
  flex: 0;
}

.Gap0 {
  gap: 0;
}

.Gap05 {
  gap: .5rem;
}

.Gap02 {
  gap: .2rem;
}

.Gap1 {
  gap: 1rem;
}

.Gap2 {
  gap: 1rem;
}

.Pad1 {
  padding: 1rem;
}

.Pad05 {
  padding: .5rem;
}

.PadV05 {
  padding: .5rem 0;
}

.PadH05 {
  padding: 0 .5rem;
}

.PadL05 {
  padding-left: .5rem;
}

.PadR05 {
  padding-right: .5rem;
}

.PadT05 {
  padding-top: .5rem;
}

.PadB05 {
  padding-bottom: .5rem;
}

.Pad0-05 {
  padding: 0 .5rem;
}

.BGLightGray {
  background: rgba(237, 242, 247, 1);
}

.BGDarkGray {
  background: #555;
  color: white;
}

.BGDogdgerBlue {
  background: dodgerblue;
}


.BGddd {
  background: #ddd;
}

.BGRed {
  background: red;
}

.BGTomato {
  background: tomato;
}


.BGLime {
  background: lime;
}

.BGWhite {
  background-color: white;
}

.BDGray {
  border: 1px solid #ccc;
}

.BDLightGray {
  border: 1px solid #ccc;
}


.BDRed {
  border: 1px solid;
  border-color: red;
}

.BDLime {
  border: 1px solid;
  border-color: lime;
}

.BDPurple {
  border: 1px solid;
  border-color: purple;
}

.BD3 {
  border-width: 3px;
}

.BD_TB_1Gray {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.H2 {
  height: 2rem;
}

.H100 {
  height: 100%;
}

.W2Rem {
  width: 2rem;
}

.W3Rem {
  width: 3rem;
}

.W4Rem {
  width: 4rem;
}

.FlexGap0 {
  display: flex;
  gap: 0;
}

.Relative {
  position: relative;
}

.OFAuto {
  overflow: auto;
}

.OFHidden {
  overflow: hidden;
}

.ArrStatus {
  background: rgb(177, 255, 177);
  border-radius: .2rem;
  padding: .3rem .3rem .1rem;
}

.ArrStatus.Draft {
  background: rgb(255, 220, 155)
}

.ArrStatus.PreviouslyPublished {
  background: rgb(155, 207, 255)
}

.NoWrap {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.MeterWrapper {
  width: 3.5rem;
}

.TACenter {
  text-align: center;
}

.TextLight {
  font-weight: 300;
  ;
}

/*--------------------------------------------------------------*/
.ContentFrame {
  background: white;
  padding: 1rem;
}

.CardsWrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1rem;
}

.CardsWrapper>* {
  /* background: yellow; */
}

.Pill {
  border-radius: .4rem;
  padding: .2rem .5rem .2rem .5rem;
  color: black;
  font-size: 80%;
}

.Card {}

/*--------------------------------------------------------------*/

/* .Centered {
  margin: 0 auto;
  width: 100%;
}

.CenterContent {

  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: .5rem;
  padding: .5rem;

}

.CenterContent>* {
  width: calc(min(50rem, 100%))
} */

.FullwidthContent {
  /* border: 3px solid lime; */
}

/*--------------------------------------------------------------*/






/*--------------------------------------------------------------*/

.Small {
  font-size: 80%;
}

.Large {
  font-size: 120%;
}

.MaxW40Rem {
  max-width: 40rem;
}

button {
  cursor: pointer;
}

button.Small {
  padding: 0 .3rem .1rem .3rem;
}

button.Active {
  padding: .1rem .4rem .2rem;
  background-color: dodgerblue;
  color: white;
  border: 1px solid rgb(25, 123, 221);
  border-radius: .2rem;
}

button.Selected {
  padding: .1rem .31rem .2rem .31rem;
  background-color: rgb(88, 206, 88);
  color: white;
  border: 1px solid rgb(69, 163, 69);
  border-radius: .2rem;
}

button.Alert {
  padding: .1rem .31rem .2rem .31rem;
  background-color: #ff6347;
  color: white;
  border: 1px solid #d6523a;
  border-radius: .2rem;
}

input.small {
  padding: .1rem;
  font-size: 90%;
  width: 6rem;
}

.RoundedBorder {
  display: flex;
  flex-direction: row;
  border: 2px solid gray;
  border-radius: .4rem;
  padding: .5rem;
}

.Grid3Cols {
  display: grid;
  grid-template-columns: 1fr 1fr fr;
}

.Grid3ColsX {
  display: grid;
  grid-template-columns: 1fr 1fr 10rem;
}

.VideoLink {
  display: block;
  padding: 1rem 0;
}

.Shadow {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.Z1 {
  z-index: 1;
}

.Badge {
  font-size: .7rem;
  /* text-transform: uppercase; */
  /* font-weight: bold; */
  padding: .0rem .3rem .0rem .3rem;
  border-radius: .3rem;
  background: #666;
  color: white;
}

.BadgeOuter {
  font-size: .7rem;
  padding: .0rem .0rem .0rem .3rem;
  display: flex;
  gap: .5rem;
  border-radius: .3rem;
  background: #666;
  color: white;
}

.FormGrid {
  display: grid;
  grid-template-columns: [labels] auto [controls] 1fr;
  grid-auto-flow: row;
  gap: .5rem;
  /* background: #eee; */
}

.FormGrid>label {
  text-align: right;
  grid-row: auto;
  grid-column: labels;
}

.FormGrid>input,
.FormGrid>textarea,
.FormGrid>select,
.FormGrid>button {
  grid-row: auto;
  grid-column: controls;
}

.Frame {
  border: 2px solid #ddd;
  border-radius: .3rem;
  padding: .5rem;
}

.PageShadow {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.Pointer {
  cursor: pointer;
}

.SvgIcon {
  width: 1.5rem;
  height: 1.5rem;
  fill: orange;
}