.termin-1-oversikt {
    background-color: dodgerblue;
}

.musiklara-termin-1 {
    background-color: dodgerblue;
}

.t1-harmonik-grund {
    background-color: dodgerblue;
}

.t1-satsteknik {
    background-color: dodgerblue;
}

.t1-gehor-grund {
    background-color: dodgerblue;
}

.t1-korgehor {
    background-color: dodgerblue;
}