.key {
    cursor: pointer;
}

.whiteKey:hover {
    fill: #eee;
}

.whiteKey {
    fill: white;
}

.blackKey {
    fill: black;
}

.blackKey:hover {
    fill: #555;
}

.key:active {
    /* fill: dodgerblue; */
    fill: orange;
}

.SvgKeyboard {
    margin: .5rem;
}