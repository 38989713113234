:root {
  --dark-bg-color: #533575;
  --dark-bg-color-hover: white;
  /* -dark-bg-color: var(--dark-bg-color); */
}

.MD p {
  /* line-height: 150%; */
  margin-bottom: 1rem;
}

.MD ul {
  margin-bottom: 1rem;
}

.MD a {
  color: orange;
  text-decoration: none;
}

.MD h2 {
  /* color: red; */
  padding-top: .6rem;
  /* border-top: 1px solid #cdcdcd; */
  display: block;
}


.Access0 {
  background-color: rgba(56, 161, 105, 1);
}

.Access1 {
  background-color: rgba(49, 130, 206, 1);
}

.Access2 {
  background-color: rgb(201, 120, 0);
}


.Access3 {
  background-color: tomato;
}

a {
  color: inherit;
  text-decoration: none;
}

p>a {
  color: orange;
}

audio {
  margin: .5rem 0rem;
  max-width: 20rem;
}

img {
  padding: 0.5rem 0;
  max-width: 100%;
}

video {
  width: 100%;
}

.FrontItem {
  display: flex;
  flex-direction: column;
  background: white;
  gap: 0;
  border-radius: .3rem;
}

.FrontItem>h4 {
  background-color: var(--dark-bg-color);
  font-size: 90%;
  color: white;
  margin: 0;
  border-top-left-radius: .3rem;
  border-top-right-radius: .3rem;
}

.DiscussionItems {
  /* border: 3px solid purple; */
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  ;
}

.DiscussionItem {

  flex: 1;
  display: flex;
  /* justify-content: flex-end; */
  flex-direction: column;

}

.DiscussionItemHeader {
  font-size: 80%;

  color: gray;
  display: flex;
  /* justify-content: flex-end; */
}

.DiscussionItemHeader div {
  background: #efefef;
  padding: .2rem .5rem;
  display: flex;
  gap: .5rem;
  align-items: center;
  border-top-left-radius: .3rem;
  border-top-right-radius: .3rem;
}

.DiscussionItemText {
  display: flex;
  /* justify-content: flex-end; */
}

.DiscussionItemText p {
  padding: .5rem;
  width: 50%;
  background: #efefef;
  border-bottom-left-radius: .3rem;
  border-bottom-right-radius: .3rem;

  /* text-align: right; */
}


body {
  overflow: hidden;
}

header,
footer {
  padding: .5rem;
}

header {
  background: white;
  border-bottom: 1px solid #ccc;
}

footer {
  background: #efefef;
}

.Center {
  max-width: 60rem;
  margin: 0 auto;
}

main {
  padding: .5rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

[data-reach-tab-list] {

  background: white;

}

.react-tabs__tab-list {}


.BookHeader {
  border-bottom: 1px solid #ccc;
  color: rgba(113, 128, 150, 1);
}

.bokyllor {}

.bokhyllor ul {
  display: grid;
  /* grid-template-columns: repeat(auto-fill, minmax(8rem, 1fr)); */
  grid-template-columns: repeat(auto-fill, minmax(11rem, 1fr));
  list-style-type: none;
  padding: 1rem .5rem 2rem .5rem;
  gap: 1.5rem;
  border-bottom: 1px solid #ccc;

}

.bokhyllor li {
  border: 1px solid #ccc;
  border-radius: .15rem;
  width: calc(1vw * .5 + 10rem);
  min-width: calc(1vw * .5 + 10rem);
  height: calc(1vw * .5 + 12rem);
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  padding: 0rem;
  cursor: pointer;
}

.bokhyllor li:hover {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.2), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.bokhyllor li h4 {
  font-size: 90%;
  /* font-weight: 700; */
  margin: 0;
  /* margin-top: .5rem; */
  line-height: 1.2;
  background-color: #ffffffcc;
  padding: .5rem;
}


.bok {
  display: flex;
  flex-direction: row;
  flex: 1;
  /* border: 1px solid red; */
}

.bok article {
  flex: 1
}

.bok aside {
  background: #efefef;
  height: 100%;
  width: 20%;
  min-width: 12rem;
  padding: .5rem
}

details {
  padding-left: .5rem;
}

summary {
  cursor: pointer;
}

aside {
  background: rgba(237, 242, 247, 1);
  min-width: 12rem;
  /* color: white; */
  padding: .5rem;
  overflow-y: auto;
}

.TOC {
  text-decoration: none;
  padding: .1rem .5rem;
  border-radius: .2rem;
  font-size: 0.875rem;
  cursor: pointer;
  color: inherit;
}

.TOC:visited {
  color: inherit;
}

.TOCChapter {
  font-weight: bold;
}

.TOCChapter:hover {
  background: var(--dark-bg-color-hover);
}

.TOCChapter.selected {
  background: var(--dark-bg-color);
  color: white;
}

.TOCSubchapter {
  padding-left: 1rem;
}

.TOCSubchapter:hover {
  background: var(--dark-bg-color-hover);

}


.TOCSubchapter.selected {
  color: #333;
  background: white;
}

.Modal {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

/* Modal Content/Box */
.ModalContent {
  background-color: #fefefe;
  margin: 10rem auto auto auto;
  padding: 1rem;
  border: 1px solid #888;
  width: 80%;
  max-width: 40rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  min-height: 20rem
}

.ModalContent>header {
  display: flex;
  gap: .5rem;
  justify-content: space-between;
}

.ModalContent>div {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.ModalContent form {
  /* display: flex;
  flex-direction: column; */
  gap: .5rem;
  align-items: flex-end;
}

.Close {}

.Close:hover,
.Close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.MarkdownEditor {}

.MarkdownEditor textarea {
  width: 100%;
  height: 10rem;
  padding: .2rem;
  font-size: 1rem;
  font-family: inherit;
  line-height: 1.5;
}

.SvgButton {
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}

.SvgButton {
  fill: #aaa
}

.SvgButton.Active>* {
  fill: var(--dark-bg-color)
}


.SvgButton.Play>* {
  fill: #00D040;
}

.SvgButton.Stop>* {
  fill: tomato;
}

.SvgButton.Close>* {
  fill: tomato;
}

pre {
  font-family: inherit;
  border: 1px solid #ccc;
  margin: .5 0 rem;
  border-radius: .2rem;
}

.TextDark {
  color: var(--dark-bg-color);
}

.RosettaCard {
  position: relative;
  min-height: 2rem;
  /* max-width: 20rem; */
  border: 8px solid gray;
  border-radius: .5rem;
  padding: 1rem;
  display: Flex;
  justify-content: center;
  align-items: center;
  user-select: none;
}

.RosettaCardQuestion {
  border-color: lightgray;
}

.RosettaCardAnswer {
  cursor: pointer;
}

.RosettaCardAnswer:hover {
  background: #eee;
}

.RosettaCorner {
  position: absolute;
  top: 0rem;
  left: 0rem;
  width: 2rem;
  height: 2rem;
  background: gray;
  opacity: 1;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
}

.RosettaCard>.MD>p {
  font-size: 110%;
  margin-bottom: .3rem;
}

.RosettaInfo {
  background-color: rgb(255, 255, 200);
  padding: 1rem;
}

.GlossaryTerm {
  white-space: nowrap;
  font-weight: bold;
  /* color: red; */
  ;
}

.GlossaryPic {
  max-height: 1.5rem;
  padding: 0;
}

.GlossaryPic:hover {
  cursor: pointer;
  max-height: inherit;
  padding: 0;
}

.GlossaryType {
  font-size: 70%;
  color: gray;
  border: 1px solid #ccc;
  padding: 0 .3rem;
  border-radius: .3rem;
  min-width: 4rem;
  text-align: center;
  user-select: none;
}



.GlossaryItem .MD p {
  margin-bottom: 0;
}

.Pointer {
  color: pointer;

}

.ColorGray {
  color: rgb(168, 167, 167);
  font-size: 80%;
}

.Strong {
  font-weight: bold;
}

.Statuslabel {
  padding: .2rem .5rem;
  /* border-radius: .2rem; */
  font-size: 80%;
  background: #eee;

  /* margin: .1rem 0; */
}

.Översikt {
  background: white;
}

.Musiklära {
  background: white;
  /* background-image: url('/assets/rosten.webp'); */

}

.Harmonik {
  background: white;
}

.Satsteknik {
  background: white;
}

.Gehör {
  background: white;
}

.Körgehör {
  background: white;
}


.inactive {
  background: #dedede;
}

.godkand {
  background: green;
  color: white;
}

.self-estimated {
  background: dodgerblue;
  color: white;

}

.val-godkand {
  background: lime;
  color: black;
}

.active {
  background: orange;
  color: black;
}

.Label {
  /* font-weight: bold; */
  font-size: 80%;
  margin: 1rem 0 .5rem;
  border-top: solid 2px #ccc;
  color: gray;
}

.SmallText {
  font-size: 80%;
  padding: .5rem;
}

.ActiveLabel {
  padding: .3rem .5rem;
  font-size: 70%;

  white-space: nowrap;
  border-top-left-radius: .4rem;
  border-bottom-right-radius: .2rem;
  ;
  /* border-bottom-left-radius: .4rem; */
}