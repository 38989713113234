.Centered {
    margin: 0 auto;
    width: 100%;
}

.CenterContent {
    /* border: 3px solid purple; */
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: .5rem;
    padding: .5rem 1rem;

    overflow-y: auto;
}

.CenterContent>* {
    /* border: 3px solid red; */
    width: calc(min(50rem, 100%))
}

.FullwidthContent {
    /* border: 3px solid lime; */
}